/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Subtitle, Text, Image, SeparateLine, SeparateLineWrap, Button, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Elektronické systémy"} description={"Elektronické systémy"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap name={"q13ep9ji9wi"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"lgz2ao2y4fp"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Přístupový systém VAR-TEC\n\n"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Mezi přednosti systému VAR-TEC paří především online správa pomocí programu Kontrola přístupu. Systém dokáže obsloužit až 4 vstupy. Nabízí možnost připojit širokou škálu čteček a klávesnic pomocí protokolu Wiegand 26. Ovládat může magnety, otvírače i elektromechanické či elektromotorické zámky. Díky odchodovému tlačítku jde systém propojit prakticky se všemi zvonkovými systémy. Program Kontrola přístupu je dodáván zdarma. Samozřejmostí je správa čipů a přehled provozu.<br>"}>
              </Text>

              <Text className="text-box text-box--center" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Napájení 230V AC<br>Až 4 čtečky<br>Až 5000+ karet<br>Maximální zatížení relé 24V/5A<br>Nastavení zámku 0 - 99 sekund<br>Možnost záložního akumulátoru<br>Odchodové tlačítko<br>Tamper napojení na EZS</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Cenová kalkulace</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Jednotka&nbsp;BC800NT4:&nbsp;5 490 Kč<br>Čtečka&nbsp;READER EM CR21-mini (čipy):&nbsp;690 Kč<br>Klávesnice&nbsp;RASK2-EM:&nbsp;890 Kč<br>Zámek/Otvírač: dle požadavku<br>Cena za montáž: po zaměření systému a konzultaci<br><br>Veškeré ceny jsou uvedeny bez DPH<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/542d7e9af9a44b10b8a78bd2e1ef1f17_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/542d7e9af9a44b10b8a78bd2e1ef1f17_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/542d7e9af9a44b10b8a78bd2e1ef1f17_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/542d7e9af9a44b10b8a78bd2e1ef1f17_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"wfzjpawa73t"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Přístupový systém PS2"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Systém PS2 je určen pro montáž do&nbsp; zvonkových systému Czechphone, ale dá se s ním doplnit i stávající instalace. Mezi jeho přednosti patří možnost konfigurace pomocí počítače a to buďto USB kabelem, nebo přes bezdrátovou technologii BLUETOOTH. Systém může ovládat přímo zámek, nebo jej jde rozšířit o další dveře za pomoci rozhraní čtečky.&nbsp; Samozřejmostí je správa čipů a přehled provozu.<br>"}>
              </Text>

              <Text className="text-box text-box--center" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Napájení 12 až 15V DC<br>Až 250 čteček<br>Až 3500 karet<br>Maximální zatížení relé 24V/2A<br>Nastavení zámku 0 - 250 sekund<br>Možnost záložního akumulátoru<br>Odchodové tlačítko</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Cenová kalkulace</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Jednotka PS2 BT:&nbsp; 4544 Kč<br>Čtečka&nbsp;READER EM CR21-mini (čipy):&nbsp;690 Kč<br>Klávesnice&nbsp;RASK2-EM:&nbsp;890 Kč<br>Zámek/Otvírač: dle požadavku<br>Cena za montáž: po zaměření systému a konzultaci<br><br>Veškeré ceny jsou uvedeny bez DPH<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/584ac0fe50464a46ae7ab7abe2915101_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/584ac0fe50464a46ae7ab7abe2915101_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/584ac0fe50464a46ae7ab7abe2915101_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/584ac0fe50464a46ae7ab7abe2915101_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"58st3n103iu"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Video systém&nbsp; V-Line"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Video systém V-Line pro panelové i rodinné domy patří mezi cenově přijatelné s vysokou kvalitou. Mezi jeho přednosti patří široká nabídka domácích stanic a možnost přesměrováni video hovoru na mobilní aplikaci ať už jste kdekoli na světě. Hlasová navigace a integrovaný přístupový systém usnadňuje obsluhu.<br>"}>
              </Text>

              <Text className="text-box text-box--center" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Napájení 24V DC<br>Varianta do stěny i na stěnu<br>Papírová jmenovka<br>Přímá volba nebo klávesnice<br>2 vodiče<br>Širokoúhlá barevná kamera<br>Až 32 účastníků<br>Až 320 karet<br>Maximální zatížení relé 12V/270mA až 24V/1A<br>Nastavení zámku 0 - 99 sekund<br>Odchodové tlačítko</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Cenová kalkulace</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Zvonkové tablo V32R-ZAP: 2790 Kč až 7579 Kč<br>Domácí stanice: 2790 Kč až 4999 Kč<br>Napájecí zdroj VT-SEPv2+PS2: 1199 Kč<br>Cena za montáž: po zaměření systému a konzultaci<br><br>Ceny se můžou měnit na základě velikosti zvonkového tabla. Veškeré ceny jsou uvedeny bez DPH<br>"}>
              </Text>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/19567/evs-katalog-v-line-2023.pdf"} content={"Katalog ke stažení"} document={"387a8e2603b44b05b87c09b7e4ca6e24"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/7c21a067d44c4340bb73251b9fe8192a_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/7c21a067d44c4340bb73251b9fe8192a_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/7c21a067d44c4340bb73251b9fe8192a_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/7c21a067d44c4340bb73251b9fe8192a_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"jedpeqf5u3"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Audio systém&nbsp;FLATLINE&nbsp;"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Kvalitní audio systém od českého výrobce Czechphone disponuje integrovaným přístupový systém a kódovou klávesnicí. Varianta s papírovou jmenovkou, nebo elektronickou E-Ink konfigurovatelnou pomocí WiFi.&nbsp;<br>"}>
              </Text>

              <Text className="text-box text-box--center" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Napájení 15 až 24V DC<br>Varianta do stěny<br>Papírová jmenovka nebo E-INK<br>Volba pomocí klávesnice<br>2+1 vodiče<br>Až 96 účastníků<br>Až 1000 karet<br>Maximální zatížení relé 24V/2A<br>Nastavení zámku 0 - 250 sekund</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Cenová kalkulace</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Zvonkové tablo: 15603 Kč až 66108Kč<br>Domácí stanice VIOLA DS15: 963 Kč<br>Napájecí zdroj NM HDR 60-15: 1297 Kč<br>Cena za montáž: po zaměření systému a konzultaci<br><br>Ceny se můžou měnit na základě velikosti zvonkového tabla. Veškeré ceny jsou uvedeny bez DPH<br>"}>
              </Text>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/19567/cz-flatline-03-2023-1.pdf"} target={null} content={"Katalog ke stažení"} document={"0832fdf851044a3bb817c127043f5871"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/04d716d731e147b0824429b2c8c40909_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/04d716d731e147b0824429b2c8c40909_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/04d716d731e147b0824429b2c8c40909_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/04d716d731e147b0824429b2c8c40909_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"sxuhl11nxm"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Sada IP videotelefonu&nbsp; HIKVISION"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Sada IP systému 2. generace složená z videotelefonu DS-KH6320-WTE1, dveřní stanice DS-KV8113-WME1(C), switche se 4 PoE porty a 16GB SD karty. Videotelefon má obrazovku 7\" s rozlišením 1024×600 px, dveřní stanice má hliníkový čelní panel, kamerový modul má rozlišení 2 Mpx, dva výstupní kontakty (pro napájení zámku nutné použít externí zdroj). Sada umožňuje sdílení video hovoru s mobilní aplikací HIK-CONNECT pro smartphone/tablet.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Cenová kalkulace</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Cena sady: 10844 Kč<br>Cena za montáž: po zaměření systému a konzultaci<br><br>Veškeré ceny jsou uvedeny bez DPH<br>"}>
              </Text>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/19567/produktovy-list-2301-027.pdf"} target={null} content={"Produktový list"} document={"69f49471f18f47b5880ca594a6b2982c"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/93ba098598ef47e1bdb916b9e58a2e81_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/93ba098598ef47e1bdb916b9e58a2e81_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19567/93ba098598ef47e1bdb916b9e58a2e81_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19567/93ba098598ef47e1bdb916b9e58a2e81_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"5eikjkno9se"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Audio sada CZECHPHONE"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Pokud sháníte levný přesto kvalitní audio systém pro rodinný dům je tato sada tou správnou volbou. Sada obsahuje zvonkové tablo pro povrchovou montáž, vnitřní audio jednotku a napájecí zdroj. Jedná se o variantu systému 4+n, tudíž k montáži je potřeba 5-ti vodičů. Samozřejmostí je ovládání zámku a podsvícení jmenovky.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Cenová kalkulace</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Cena sady:&nbsp;2516 Kč<br>Cena za montáž: po zaměření systému a konzultaci<br><br>Veškeré ceny jsou uvedeny bez DPH<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/01b03327f5f74b86bc33fce272925f93_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/01b03327f5f74b86bc33fce272925f93_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19567/01b03327f5f74b86bc33fce272925f93_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"tew8n5nhdu"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Kamerový systém HIKVISION"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Kamerové systémy HIKVISIN patří mezi špičky v oblasti monitorování a zastřežení. Systémy montujeme včetně monitoru pro lokální dohled. Samozřejmostí systému je i možnost vzdáleného dohledu prostřednictvím připojení k internetu, nahrávací smyčka, zasílání notifikací a mnoho dalšího.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Cenová kalkulace</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"NVR DS-7608NI-K1/8P(C):&nbsp;7446 Kč\n<br>Kamera&nbsp;DS-2CD2143G2-I - (2.8mm): 4412 Kč<br>HDD 2TB: 1660 Kč<br>Monitor&nbsp;DS-D5024FN/EU: 2867 Kč<br>Cena za montáž: po zaměření systému a konzultaci<br><br>Veškeré ceny jsou uvedeny bez DPH<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/9ac7e6dac956496d859492c500416c6c_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/9ac7e6dac956496d859492c500416c6c_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/9ac7e6dac956496d859492c500416c6c_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/9ac7e6dac956496d859492c500416c6c_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"s9la190lm1a"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"j7ab2595fc"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Zabezpečovací systém HIKVISION"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Zvýhodněná sada bezdrátového zabezpečovacího systému HIKVISION AX PRO pro menší objekty jako kanceláře, rodinné domy a garáže. Systém zaujme především bezdrátovou technologii díky které není potřeba tahat kabeláž. Systém jde dále rozšiřovat.<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":399}} content={"<span style=\"font-weight: bold;\">Cenová kalkulace</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":399}} content={"Cena sady:&nbsp;18387 Kč\n<br>Cena za montáž: po zaměření systému a konzultaci<br><br>Veškeré ceny jsou uvedeny bez DPH<br>"}>
              </Text>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/19567/produktovy-list-2301-112.pdf"} content={"Produktový list"} document={"7e036c5eb2e04b0fa8ee455da7be50a3"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19567/d380047c8d3747fdae193d2ce909c64a_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/d380047c8d3747fdae193d2ce909c64a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19567/d380047c8d3747fdae193d2ce909c64a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19567/d380047c8d3747fdae193d2ce909c64a_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}